import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import { ID } from "../classAndIds"
import QuickLinks from "./quicklinks"
import { SettingsProvider } from "../context/SettingsContext"
import { StateProvider } from "../context/StateContext"

const Layout = ({ pageSlug, children }) => {
  return (
    <SettingsProvider>
      <StateProvider>
        <QuickLinks />
        <Header />
        <main id={ID.MAIN} className={pageSlug || null}>
          {children}
        </main>
        <Footer />
      </StateProvider>
    </SettingsProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
