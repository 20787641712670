// Queries the CMS for global csite configuration settings

import { graphql, useStaticQuery } from "gatsby"

const useSiteCongfigurationQuery = () => {
  return useStaticQuery(graphql`
    query {
      CMS {
        configuration {
          id
          recaptcha
          metadata {
            ...Metadata
          }
          sitenav {
            ...NavigationItem
          }
          footerLocation {
            ...Locations
          }
          footerLinks {
            ...NavigationItem
          }
        }
      }
    }
  `)
}

export default useSiteCongfigurationQuery
