import React, { createContext, useState } from "react"

const StateContext = createContext()

function StateProvider({ children }) {
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [bannerInView, setBannerInView] = useState(false)

  const toggleNav = () => setNavIsOpen(!navIsOpen)
  const closeNav = () => setNavIsOpen(false)
  const openNav = () => setNavIsOpen(false)

  return (
    <StateContext.Provider
      value={{
        navIsOpen,
        bannerInView,
        setBannerInView,
        toggleNav,
        closeNav,
        openNav,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export { StateProvider, StateContext }
