import React from "react"
import useParseHTMLString from "../hooks/useParseHTMLString"
import { VARIANTS } from "../classAndIds"

const CallToAction = ({
  isButton,
  target,
  label,
  alignCenter = false,
  newTab = false,
}) => (
  <div data-align-center={alignCenter || null}>
    <a
      href={target}
      data-type={isButton ? VARIANTS.BUTTON : VARIANTS.CTA}
      title={`Open line ${newTab ? "in a new tab" : "in your browser"}`}
      target={newTab ? "_blank" : null}
    >
      {useParseHTMLString(label)}
    </a>
  </div>
)

export default CallToAction
