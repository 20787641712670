import { Link } from "gatsby"
import React, { useContext } from "react"
import SiteNav from "./sitenav"
import NavToggler from "./navToggler"
import { SettingsContext } from "../context/SettingsContext"

import { ELEMENTS } from "../classAndIds"
import Logo from "./rvsLogo"

const Header = () => {
  const settings = useContext(SettingsContext) || { metadata: { sitename: "" } }

  return (
    <header className={ELEMENTS.PAGE_HEADER}>
      <div>
        <div className={ELEMENTS.LOGO}>
          <h1>{settings.metadata.sitename}</h1>
          <a href="/" title="Go to the RVS website homepage">
            <Logo />
          </a>
        </div>
        <SiteNav />
        <NavToggler />
      </div>
    </header>
  )
}

export default Header
