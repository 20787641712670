import React, { useContext } from "react"
import { ELEMENTS, CONTENT_TYPES, VARIANTS } from "../classAndIds"
import NavItem from "./navItem"
import { StateContext } from "../context/StateContext"
import { SettingsContext } from "../context/SettingsContext"
import Heading from "./heading"
import Richtext from "./richtext"
import FooterLogo from "../../static/inlinesvg/footerLogo.svg"

import { useScrollToPosition } from "../hooks/useScrollWindow"
import IconButton from "./iconButton"

const Footer = () => {
  const settings = useContext(SettingsContext)
  const items = settings.footerLinks

  const { bannerInView } = useContext(StateContext)
  const { phone, address } = settings.footerLocation.locations[0]
  const { label, num, country } = phone

  return (
    <footer className={ELEMENTS.PAGE_FOOTER}>
      <section className={ELEMENTS.LOGO}>
        <a href="/" title="Return to RVS Home page">
          <FooterLogo />
        </a>
      </section>
      <nav>
        <h1>Links</h1>
        <ul>
          {items.map(item => (
            <li key={`footer-link-${item.target}`}>
              <NavItem {...item} />
            </li>
          ))}
        </ul>
      </nav>
      <section className={CONTENT_TYPES.LOCATION}>
        <Heading level="1" text="Contact" />
        <Richtext text={address} />
        <p>
          {label}:&nbsp;
          <a href={`tel:${country}${num}`} title={`Phone number for ${label}`}>
            +{country} {num}
          </a>
        </p>
      </section>
      <IconButton
        variant={VARIANTS.SCROLL_TO_TOP}
        scale="1.3"
        onClick={() => useScrollToPosition(0)}
        label={"Back to top"}
        disabled={bannerInView}
      />
    </footer>
  )
}

export default Footer
