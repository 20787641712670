import React from "react"
import useParseHTMLString from "../hooks/useParseHTMLString"

const NavItem = ({ target, label, className, newTab = false }) => {
  const type = className.toLowerCase()
  const isDefaultType = type === "default"
  const title = `Open ${label} in ${newTab ? "a new tab" : "your browser"}`

  return (
    <a
      href={target}
      data-type={isDefaultType ? null : type}
      title={title}
      target={newTab ? "_blank" : null}
    >
      {useParseHTMLString(label)}
    </a>
  )
}

export default NavItem
