import React from "react"
import { CONTENT_TYPES, CONTAINERS } from "../classAndIds"
import Img from "gatsby-image/withIEPolyfill"
import useFeatureAnimationQuery from "../hooks/useFeatureAnimationQuery"

const FeatureAnimation = () => {
  const data = useFeatureAnimationQuery()
  const { bg, images } = data.CMS.featureAnimation
  console.dir(images[0])

  return (
    <section
      data-type={CONTAINERS.COMPONENT}
      data-contains={CONTENT_TYPES.FEATURE_ANIMATION}
    >
      <figure data-contains={CONTENT_TYPES.BACKGROUND}>
        <Img fluid={bg.urlSharp.childImageSharp.fluid} alt="" />
      </figure>

      <section
        data-type={CONTAINERS.CANVAS}
        data-contains={CONTENT_TYPES.SLIDE}
      >
        {images.map((image, index) => {
          const { width, height } = image.urlSharp.childImageSharp.fixed
          const fixedHeight = 300
          const fixedWidth = Math.round((width / height) * fixedHeight)
          return (
            <Img
              key={`slide-${index}`}
              fixed={image.urlSharp.childImageSharp.fixed}
              alt=""
              width={fixedWidth}
              height={fixedHeight}
              style={{ position: "absolute" }}
            />
          )
        })}
      </section>
    </section>
  )
}

export default FeatureAnimation
