import React, { useState, useEffect, useContext } from "react"
import { useForm } from "react-hook-form"
import Richtext from "../richtext"
import Heading from "../heading"
import { SettingsContext } from "../../context/SettingsContext"
import { Row, SubmitButton } from "./formElements"
import FormFieldFactory from "../../FormFieldFactory"
import stringToFormMethod from "../../util/stringToFormMethod"
import { ELEMENTS, CONTENT_TYPES, CONTAINERS } from "../../classAndIds"

import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3"

const FORM_STATE = {
  OPEN: 0,
  READY_TO_SEND: 1,
  AWAITING_RESPONSE: 5,
  SUCCESS: 10,
  ERROR: 20,
}

const BasicForm = props => {
  const { fields, classNames, ...settings } = props.form

  const { register, handleSubmit } = useForm()
  const { recaptcha } = useContext(SettingsContext)
  const [formData, setFormData] = useState({})

  const [submitLabel, setSubmitLabel] = useState(settings.submitLabel)
  const [responseMessage, setResponseMessage] = useState()
  const [formState, setFormState] = useState(FORM_STATE.OPEN)
  const method = stringToFormMethod(settings.method)

  const {
    title,
    description,
    endpoint,
    useCaptcha,
    apiResponses,
    successMessage,
    failMessage,
  } = settings

  const onSubmit = data => {
    setFormData(data)
    setFormState(FORM_STATE.READY_TO_SEND)
  }

  useEffect(() => {
    if (formState !== FORM_STATE.READY_TO_SEND) return

    setSubmitLabel("Sending")

    const sendData = async (endpoint, formData) => {
      const encodedData = Object.keys(formData).map(
        key => `${key}=${encodeURIComponent(formData[key])}`
      )

      const fetchOptions = {
        method,
        headers: new Headers({
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        }),
      }

      if (encodedData.length > 0) {
        if (method === "GET") {
          endpoint += `?${encodedData.join("&")}`
        } else {
          fetchOptions.body = encodedData
        }
      }

      try {
        const res = await fetch(endpoint, { method, fetchOptions })
        res
          .json()
          .then(res => {
            let success = false

            for (var i = 0; i < apiResponses.length; i++) {
              const apiResp = apiResponses[i]
              if (res[apiResp.name] && res[apiResp.name] === apiResp.value) {
                success = apiResp.status === "success"
                break
              }
            }

            setFormState(success ? FORM_STATE.SUCCESS : FORM_STATE.ERROR)
            setResponseMessage(success ? successMessage : failMessage)
          })
          .catch(err => {
            setFormState(FORM_STATE.ERROR)
            setResponseMessage(failMessage)
          })
      } catch (err) {
        console.dir(err)
        setFormState(FORM_STATE.ERROR)
        setResponseMessage(failMessage)
      }
    }

    sendData(endpoint, formData)
    setFormState(FORM_STATE.AWAITING_RESPONSE)
  }, [formState])

  const fieldElements = fields.map(field => {
    const data = {
      ...field,
      state: formData[field.name],
      ref: register,
    }

    return <Row key={field.name}>{FormFieldFactory.create(data)}</Row>
  })

  const intro = (
    <>
      {title && <Heading text={title} className={ELEMENTS.TITLE} level={"4"} />}
      {description && (
        <div className={ELEMENTS.BODY}>
          <Richtext text={description} />
        </div>
      )}
    </>
  )

  const submitEl = <SubmitButton label={submitLabel} />

  const responseEl = (
    <section data-type={CONTAINERS.MESSAGE}>
      <Heading
        text={
          formState == FORM_STATE.SUCCESS ? "Thank you" : "Something went wrong"
        }
        level={"5"}
      />
      <Richtext text={responseMessage} />
    </section>
  )

  const formEl = (
    <form
      data-type={CONTAINERS.COMPONENT}
      data-contains={CONTENT_TYPES.FORM}
      className={classNames ? classNames.join(" ") : null}
      action={endpoint}
      method={method}
      onSubmit={handleSubmit(onSubmit)}
    >
      {intro}
      {fieldElements.map(el => el)}

      {useCaptcha && (
        <Row key="recaptcha">
          <GoogleReCaptcha
            onVerify={token => console.log(`Catpcha Token:\n${token}`)}
          />
        </Row>
      )}

      <Row key="formEnd">
        {formState <= FORM_STATE.AWAITING_RESPONSE && submitEl}
        {formState > FORM_STATE.AWAITING_RESPONSE && responseEl}
      </Row>
    </form>
  )

  return useCaptcha ? (
    <GoogleReCaptchaProvider reCaptchaKey={recaptcha}>
      {formEl}
    </GoogleReCaptchaProvider>
  ) : (
    formEl
  )
}

export default BasicForm
