import { graphql, useStaticQuery } from "gatsby"

const useFeatureAnimationQuery = () => {
  return useStaticQuery(graphql`
    query {
      CMS {
        featureAnimation {
          bg {
            ...Image
          }
          images {
            ...FeatureAnimationImage
          }
        }
      }
    }
  `)
}

export default useFeatureAnimationQuery
