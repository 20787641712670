import React from "react"
import { CONTENT_TYPES } from "../classAndIds"

const IconButton = props => {
  const { scale = 1, label, variant, ...generalProps } = props
  const transform = `scale(${scale})`

  return (
    <button
      style={{ transform }}
      data-type={CONTENT_TYPES.ICON}
      data-variant={variant}
      onMouseUp={e => e.target.blur()}
      {...generalProps}
    >
      {label}
    </button>
  )
}

export default IconButton
