import React from "react"
import { CONTENT_TYPES, CONTAINERS } from "../classAndIds"
import Img from "gatsby-image/withIEPolyfill"
import Heading from "./heading"
import Richtext from "./richtext"
const Bio = ({ id, name, summary, bio, jobTitle, photo }) => {
  return (
    <article
      data-type={CONTAINERS.COMPONENT}
      data-contains={CONTENT_TYPES.PERSON}
    >
      <figure>
        <div>
          {photo && (
            <Img
              fluid={photo.urlSharp.childImageSharp.fluid}
              alt={`${name} ${jobTitle ? ", " + jobTitle : ""}`}
              objectFit={"scale-down"}
              imgStyle={{ objectFit: "scale-down" }}
            />
          )}
        </div>
        <figcaption>
          <Heading text={name} level={"h1"} />
          {jobTitle && <Heading text={jobTitle} level={"h2"} />}
          <Richtext text={summary} />
          {bio && (
            <>
              <input id={`chk-${id}`} type="checkbox" />
              <label htmlFor={`chk-${id}`}>Read More</label>
              <div className="extended">
                <Richtext text={bio} />
              </div>
            </>
          )}
        </figcaption>
      </figure>
    </article>
  )
}

export default Bio
