import React from "react"
import Richtext from "./richtext"
import Img from "gatsby-image/withIEPolyfill"
import toCamelCase from "../util/toCamelCase"
import Heading from "./heading"

import { CONTENT_TYPES, CONTAINERS } from "../classAndIds"

const FeatureImage = ({
  title,
  alt,
  caption,
  text_position,
  image,
  classNames = [],
}) => {
  classNames.push(toCamelCase(text_position))

  const displayText = text_position.toLowerCase() !== "hidden"
  const captionEl = (
    <figcaption>
      {title && <Heading level="1" text={title} />}
      {caption && <Richtext text={caption} />}
    </figcaption>
  )

  return (
    <figure
      data-type={CONTAINERS.COMPONENT}
      data-contains={CONTENT_TYPES.FEATURE_IMAGE}
      data-text-position={text_position}
      className={classNames.join(" ")}
    >
      <Img fluid={image.urlSharp.childImageSharp.fluid} alt={alt} />
      {displayText && captionEl}
    </figure>
  )
}

export default FeatureImage
