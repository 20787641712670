// ToDO: Build out location component
import React, { Fragment } from "react"
import Img from "gatsby-image/withIEPolyfill"
import Richtext from "./richtext"
import Heading from "./heading"
import { CONTENT_TYPES, CONTAINERS } from "../classAndIds"

export const LocationWithMap = ({
  region = "",
  address,
  title,
  icon,
  phone = "",
}) => {
  return (
    <figure>
      <figcaption>
        <Location {...{ region, address, title, phone }} />
      </figcaption>
      <LocationMap {...{ icon }} />
    </figure>
  )
}

export const Location = ({ region = "", address, title, phone = "" }) => (
  <div
    data-type={CONTAINERS.COMPONENT}
    data-contains={CONTENT_TYPES.LOCATION}
    className="location"
  >
    <input type="checkbox" id={`chk-${phone.num}`} />
    <label htmlFor={`chk-${phone.num}`}>
      {region && <Heading text={region} level="1" />}
      {title && <Heading text={title} level="2" />}

      <div className="detail">
        {address && <Richtext text={address} />}

        {phone && (
          <p>
            {phone.label ? `${phone.label}: ` : ""}
            <a
              href={`tel:${phone.country || ""}${phone.num}`}
              title="Phone number for {label}"
            >
              {phone.country ? `+${phone.country} ` : ""}
              {phone.num}
            </a>
          </p>
        )}
      </div>
    </label>
  </div>
)

export const LocationMap = ({ icon }) => (
  <div data-type={CONTAINERS.COMPONENT} className="map">
    {icon && (
      <Img
        fluid={icon.urlSharp.childImageSharp.fluid}
        alt=""
        objectFit={"scale-down"}
        imgStyle={{ objectFit: "scale-down" }}
      />
    )}
  </div>
)

export default Location
