import React from "react"
import { CONTENT_TYPES, CONTAINERS } from "../classAndIds"
import Bio from "./bio"
import Heading from "./heading"

const Team = ({ title, subtitle, members }) => {
  const memberEls = members.map(member => (
    <li key={`li-${member.id}`}>
      <Bio {...member} key={`bio-${member.id}`} />
    </li>
  ))
  return (
    <section
      data-type={CONTAINERS.COLLECTION}
      data-contains={CONTENT_TYPES.PERSON}
    >
      <header>
        {title && <Heading text={title} level={"h1}"} />}
        {subtitle && <Heading text={subtitle} level={"h2}"} />}
      </header>
      <ul>{memberEls.map(el => el)}</ul>
    </section>
  )
}

export default Team
