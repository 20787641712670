import React from "react"
import Heading from "./components/heading"
import Richtext from "./components/richtext"
import CallToAction from "./components/callToAction"
import FeatureImage from "./components/featureImage"
import FancyList from "./components/fancyList"
import FeatureAnimtion from "./components/featureAnimation"
import Callout from "./components/callout"
import BasicForm from "./components/form/BasicForm"
import Team from "./components/Team"
import Locations from "./components/Locations"

const componentTypes = {
  CMS_ComponentContentHeading: Heading,
  CMS_ComponentContentSectionHeadline: Heading,
  CMS_ComponentContentRichText: Richtext,
  CMS_ComponentContentCallToAction: CallToAction,
  CMS_ComponentContentFeatureImage: FeatureImage,
  CMS_ComponentContentFancyList: FancyList,
  CMS_ComponentContentFeatureAnimation: FeatureAnimtion,
  CMS_ComponentContentCallout: Callout,
  CMS_ComponentContentFormContainer: BasicForm,
  CMS_ComponentCollectionPeople: Team,
  CMS_ComponentCollectionLocations: Locations,
}

function* makeBasicKeyGenerator(start = Date.now()) {
  let currentKey = start
  while (true) {
    yield currentKey++
  }
}

const keyGen = makeBasicKeyGenerator()

export default class ComponentFactory {
  static get newKey() {
    const key = keyGen.next().value
    return key
  }

  static create(data) {
    try {
      const element = componentTypes[data.__typename]
      const props = {
        ...data,
        key: ComponentFactory.newKey,
      }

      return React.createElement(element, props)
    } catch (e) {
      console.error(`Unable to create component from type '${data.__typename}'`)
    }
  }
}
