// Skip to nav and content
// Accessibility aid.

import React from "react"
import { ELEMENTS, ID, QUICKLINK_LABELS } from "../classAndIds"

const QuickLinks = () => (
  <div className={ELEMENTS.QUICKLINKS}>
    <a href={`#${ID.SITE_NAV}`}>Skip to {QUICKLINK_LABELS[ID.SITE_NAV]}</a>

    <a href={`#${ID.MAIN}`}>Skip to {QUICKLINK_LABELS[ID.MAIN]}</a>
  </div>
)

export default QuickLinks
