import React from "react"
import Richtext from "./richtext"
import Img from "gatsby-image/withIEPolyfill"
import toCamelCase from "../util/toCamelCase"
import Heading from "./heading"
import { CONTENT_TYPES, CONTAINERS, ELEMENTS } from "../classAndIds"

const FancyList = ({
  headline,
  layout,
  iconPosition,
  items,
  iconBg,
  roundIcon,
}) => {
  return (
    <section
      data-type={CONTAINERS.COMPONENT}
      data-contains={CONTENT_TYPES.FANCY_LIST}
      data-layout={toCamelCase(layout)}
      data-icon-position={toCamelCase(iconPosition)}
      data-icon-bg={iconBg || null}
      data-icon-round={roundIcon || null}
    >
      {headline && <Heading level="1" text={headline} />}
      <ul>{items.map(item => listItem({ ...item }))}</ul>
    </section>
  )
}

export default FancyList

const listItem = ({ headline, text, icon }) => {
  const fluid =
    icon && icon.urlSharp ? icon.urlSharp.childImageSharp.fluid : null
  // const fluid =
  //   icon && icon.urlSharp ? icon.urlSharp.childImageSharp.fluid : null

  return (
    <li key={`a${Math.random()}`} data-contains="fancyListItem">
      <div className={ELEMENTS.ICON}>
        {fluid && (
          <Img
            fluid={fluid}
            alt=""
            objectFit="fill"
            style={{ width: "100%" }}
          />
        )}
      </div>
      {headline && (
        <Heading level="2" text={headline} className={ELEMENTS.TITLE} />
      )}
      {text && (
        <div className={ELEMENTS.BODY}>
          <Richtext text={text} />
        </div>
      )}
    </li>
  )
}
