import React from "react"
import useParseHTMLString from "../hooks/useParseHTMLString"

const levelsLookup = {
  one: "1",
  two: "2",
  three: "3",
  four: "4",
  five: "5",
  six: "6",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  h1: "1",
  h2: "2",
  h3: "3",
  h4: "4",
  h5: "5",
  h6: "6",
}

const parseLevel = level => {
  return levelsLookup[level.toLowerCase()] || "1"
}

const Heading = ({ level, text, alignCenter = false, className = null }) => {
  const content = useParseHTMLString(text)
  const lvl = parseLevel(level)

  // Hack: A bit rough here. Works fine, but a more elegant way to pass props would be good. Low Priority.
  const props = {}

  props.className = className || null
  props["data-align-center"] = alignCenter || null

  return React.createElement(`h${lvl}`, props, content)
}
export default Heading
