const isEmptyOrNull = str => {
  return str === undefined || str == null || (str.length && str.length < 1)
}
const toCamelCase = str => {
  if (isEmptyOrNull(str)) {
    return
  }
  var reg1 = new RegExp(/([\s-._]){1,}([a-z])/gi)
  var reg2 = new RegExp(/(^.)/)
  return str
    .replace(reg1, val => val.toUpperCase())
    .replace(reg1, "$2")
    .replace(reg2, val => val.toLowerCase())
}

export default toCamelCase
