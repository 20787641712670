const MACRO_TYPES = {
  OUTLINK: "outlink",
}

export default class TextMacro {
  static delimiter = "|"
  static Prefix = `${TextMacro.delimiter}macro:`
  static Suffix = `${TextMacro.delimiter}endmacro${TextMacro.delimiter}`

  static InsertMacros(str) {
    const errors = []
    const macros = extract(str, TextMacro.Prefix, TextMacro.Suffix)
    let resultString = str

    if (macros) {
      macros.forEach(macro => {
        const [type, ...params] = macro.split(TextMacro.delimiter)
        if (isValidType(type)) {
          resultString = str.replace(
            `${TextMacro.Prefix}${macro}${TextMacro.Suffix}`,
            TextMacro[type](...params)
          )
        } else {
          errors.push(`Invalid macro type: ${type}`)
        }
      })
    }

    throwErrors(errors)
    return resultString
  }

  static outlink(href, label) {
    return `<a href="${href}" target="_blank" title="Open link in a new tab">${label}</a>`
  }
}

// --------------- helper funcs ---------------

const isValidType = type => getTypesList().includes(type)
const getTypesList = () => Object.values(MACRO_TYPES)

const throwErrors = errors => {
  if (errors && errors.length > 0) {
    throw new Error(
      `${errors.join(`\n`)}\nExpected one of: ${getTypesList().join(",")}`
    )
  }
}

/**
 * Would prefer RegExp, but that triky without postiive lookaheads,
 * which aren't support in IE or Edge.
 */
const extract = (str, prefix, suffix) => {
  const macros = []
  str
    .split(prefix)
    .splice(1)
    .forEach(chunk => {
      macros.push(chunk.split(suffix)[0])
    })

  return macros.length > 0 ? macros : null
}

/** Example with Regex (NOT SUPPORTED ON IE/Edge)
import escapeForRegExp from "./util/escapeForRegExp"
 const extract = (str, prefix, suffix) => {
   const pattern = new RegExp(
     `(?<=${escapeForRegExp(prefix)}).*?(?=${escapeForRegExp(suffix)})`,
     "g"
     )
     return str.match(pattern)
    }
    */
