/* *****************************************************
    Parses HTML tags and entities

    1. Allows richtext content from the CMS to be parsed 
       into HTML objects, preserving block and flow level 
       markup (paras, lists, strong, em)

    2 Replaces specific patterns with entities or other 
      special content. The main use case for this second
      feautre is that it takes every instance of 
      "Consensus+" from CMS content, replaces with 
      "Consensus&#8314;" to render a superscript + sign.

********************************************************/

import parse from "html-react-parser"
import TextMacro from "../TextMacro"

const useParseHTMLString = (str = "") => {
  try {
    const withMacros = TextMacro.InsertMacros(str)
    // const withMacros = str
    const encoded = withMacros.replace(
      /[Cc]onsensus\+/g,
      "Consensus<sup>+</sup>"
    )
    const html = parse(encoded, {
      htmlparser2: { decodeEntities: true },
    })
    return html
  } catch (e) {
    console.error("Failed to convert to html:", str)
    console.error(e)
    return ""
  }
}

export default useParseHTMLString
