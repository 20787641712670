export const SLUGS = {
  HOME: "home",
}

export const CONTAINERS = {
  PAGE_SECTION: "pageSection",
  PAGE_COMPONENT: "sectionComponent",
  COMPONENT: "component",
  COLLECTION: "collection",
  MESSAGE: "message",
  CANVAS: "canvas",
}

export const LAYOUT = {
  COLUMN: "column",
  ROW: "row",
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
  BEFORE: "before",
  AFTER: "after",
}

export const STYLES = {
  ACCENT: "accent",
  BUTTON: "button",
}

export const VARIANTS = {
  ACCENT: "accent",
  HAMBURGER: "hamburger",
  BUTTON: "button",
  CTA: "cta",
  SCROLL_DOWN: "scrollDown",
  SCROLL_TO_TOP: "scrollTop",
}

export const ELEMENTS = {
  QUICKLINKS: "quicklinks",
  ICON: "icon",
  TITLE: "title",
  BODY: "body",
  SITE_NAV: "sitenav",
  PAGE_HEADER: "pageHeader",
  PAGE_FOOTER: "pageFooter",
  PAGE_BANNER: "pageBanner",
  LOGO: "logo",
}

export const ID = {
  MAIN: "main",
}

export const QUICKLINK_LABELS = {
  [ID.MAIN]: "Main content",
  [ID.SITE_NAV]: "Site navigation",
}

export const CONTENT_TYPES = {
  FORM: "form",
  PERSON: "person",
  CALLOUT: "callout",
  FANCY_LIST: "fancyList",
  FEATURE_IMAGE: "featureImage",
  FOOTER: "footer",
  HEADING: "heading",
  LOCATION: "location",
  COLLECTION: "collection",
  RICH_TEXT: "richtext",
  ICON: "icon",
  FEATURE_ANIMATION: "featureAnimation",
  SLIDE: "slide",
  BACKGROUND: "bg",
}
