import React, { useContext } from "react"
import NavItem from "./navItem"
import { ELEMENTS } from "../classAndIds"
import { SettingsContext } from "../context/SettingsContext"
import { StateContext } from "../context/StateContext"

const SiteNav = () => {
  const settings = useContext(SettingsContext)
  const items = settings.sitenav
  const { navIsOpen } = useContext(StateContext)

  return (
    <nav className={`${ELEMENTS.SITE_NAV}${navIsOpen ? " open" : ""}`}>
      <ul>
        {items.map((item, index) => (
          <li key={`nav-${index}-${item.target}`}>
            <NavItem {...item} />
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default SiteNav
