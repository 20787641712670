import React, { useContext } from "react"
import { StateContext } from "../context/StateContext"
import { VARIANTS } from "../classAndIds"

const NavToggler = () => {
  const { navIsOpen, toggleNav } = useContext(StateContext)
  return (
    <button
      className={`navToggle ${navIsOpen ? "open" : ""}`}
      data-type={VARIANTS.HAMBURGER}
      onClick={() => toggleNav()}
    >
      <label>Toggle Navigation</label>
    </button>
  )
}

export default NavToggler
