import React from "react"

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 360 90"
  >
    <g className="text">
      <path d="M164.831 40.234h14.17c7.138 0 13.121-3.584 13.121-10.967 0-7.493-5.774-10.64-13.226-10.64h-14.065v21.607zm27.291 46.8L175.117 56.52H164.83v30.512h-17.004V2.011h32.434c8.397 0 15.324 2.39 20.782 7.06 5.564 4.669 8.293 11.292 8.293 19.87 0 12.378-5.668 20.63-16.9 24.865l18.58 33.227h-18.894zm41.512-85.022l21.308 64.608 21.412-64.608h18.055l-29.81 85.02h-19.63l-29.704-85.02z"></path>
      <g transform="translate(296.56 .319)">
        <mask id="prefix__b">
          <path
            id="prefix__a"
            d="M.104.391h62.244v87.627H.104z"
            fill="#ffffff"
          ></path>
        </mask>

        <path
          d="M62.348 61.74c0 7.71-2.624 14.117-7.977 19.003-5.353 4.885-12.7 7.275-22.147 7.275-15.43 0-27.186-7.71-32.12-19.98L14.8 60.22c3.253 7.384 9.656 11.836 17.948 11.836 7.243 0 12.49-3.8 12.49-9.013 0-2.498-1.049-4.452-3.253-5.864-2.099-1.519-6.193-3.256-12.28-5.102-9.553-2.932-16.27-6.407-20.049-10.315-4.198-4.235-6.297-9.448-6.297-15.746 0-7.492 2.833-13.572 8.397-18.35C17.318 2.78 24.141.39 32.013.39c13.75 0 24.143 6.624 28.866 18.351l-14.066 7.927c-2.834-6.95-7.977-10.424-15.535-10.424-6.716 0-10.915 3.583-10.915 8.686 0 5.104 3.884 7.71 13.226 10.424 19.627 5.756 28.76 12.814 28.76 26.387"
          mask="url(#prefix__b)"
        ></path>
      </g>
    </g>
    <path
      className="accent"
      d="M82.42 34.503c5.424 8.918 2.833 20.699-5.788 26.311-8.622 5.613-20.01 2.932-25.435-5.987-5.425-8.919-2.834-20.698 5.789-26.312 8.62-5.612 20.008-2.93 25.433 5.988m2.991 52.672h18.172l30.035-85.02h-18.174zm-85.41 0h18.173l30.035-85.02H30.034z"
    ></path>
  </svg>
)

export default Logo
