import React, { useEffect, useContext } from "react"
import { useInView } from "react-intersection-observer"
import PageSection from "../components/pageSection"
import CallToAction from "./callToAction"
import Richtext from "./richtext"
import { VARIANTS } from "../classAndIds"
import FeatureAnimation from "../components/featureAnimation"
import IconButton from "../components/iconButton"
import { useScrollToElement } from "../hooks/useScrollWindow"
import { StateContext } from "../context/StateContext"
import { ELEMENTS, CONTAINERS } from "../classAndIds"

const PageBanner = props => {
  const {
    cta,
    bannerHeading,
    bannerCopy,
    scrollPrompt,
    featureAnimation,
    bgStyle,
  } = props

  // set a property on StateContext provider
  // indicating when the banner has scrolled out of
  // view. This is used to set a className on the ScrollToTopButton
  // so that it isn't visible until the user scrolls down the page.
  const { setBannerInView } = useContext(StateContext)
  const [ref, inView, entry] = useInView()

  useEffect(() => setBannerInView(inView), [inView])

  const layout = props.layout && props.layout.toLowerCase()
  const getFirstSectionElement = () => document.querySelector("#section_1")

  return (
    <PageSection
      className={ELEMENTS.PAGE_BANNER}
      data-layout={layout || null}
      data-bg-style={(bgStyle && bgStyle.toLowerCase()) || null}
    >
      <header
        ref={ref}
        data-type={CONTAINERS.COMPONENT}
        data-contains={ELEMENTS.PAGE_BANNER}
      >
        {bannerHeading && (
          <Richtext text={replaceParaWithHeadings(bannerHeading)} />
        )}
        {bannerCopy && <Richtext text={bannerCopy} />}
        {cta && <CallToAction {...cta} />}
      </header>
      <section className="children">
        {featureAnimation && <FeatureAnimation />}
        {scrollPrompt && (
          <IconButton
            variant={VARIANTS.SCROLL_DOWN}
            scale={1.3}
            label="Scroll Down"
            onClick={e => {
              useScrollToElement(getFirstSectionElement())
            }}
          />
        )}
      </section>
    </PageSection>
  )
}

export default PageBanner

// banner headlines are edited in the CMS as richtext
// content, to allow bold and italicised formatting.
// The richtext field wraps each block in paragraph tags
// so we need to swap them out for heirarchical heading tags
// before rendering the headline.
const replaceParaWithHeadings = str => {
  const pTags = /(^<p>|<\/p>)/g
  const paras = str.replace(pTags, "").split("<p>")
  return paras.reduce((str, para, index) => {
    const level = Math.min(index + 1, 6)
    return `${str}<h${level}>${para}</h${level}>`
  }, "")
}
