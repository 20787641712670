/**
 * Metadata comes from the CMS as an array of tags
 * These need to be merged into the global metadata state object
 * @param {*} array of tag objects [{type:string, value:string}]
 * @param {*} metadata object of metadata in name/value pairs
 */
const useMergeTagsToMetadata = (tags, metadata) => {
  if (!tags) {
    return metadata
  }

  return tags.reduce((merged, tag) => {
    return tag ? { ...merged, [tag["type"]]: tag["value"] } : merged
  }, metadata)
}

export default useMergeTagsToMetadata
