import React from "react"
import Richtext from "./components/richtext"

export default class FormFieldFactory {
  static create(data) {
    try {
      const { label, state, __typename, ...fieldProps } = data
      const fieldId = `field-${fieldProps.name}`
      const labelId = `label-${fieldProps.name}`
      const hasLabel = label && label.length > 0
      const tagName = TAG_NAMES[__typename]

      // tweak some props

      const props = addStateToProps(fieldProps, __typename, state)
      props.id = fieldId
      props.type = getInputType(fieldProps, __typename)

      return (
        <>
          {hasLabel && (
            <label
              id={labelId}
              htmlFor={fieldId}
              key={labelId}
              data-input-type={props.type}
            >
              <Richtext text={label || null} />
            </label>
          )}
          {React.createElement(tagName, props)}
        </>
      )
    } catch (e) {
      console.error(e.message)
    }
  }
}

//-------------------- helpers ---------------------

const addStateToProps = (props, typename, state) => {
  const newProps = { ...props }
  switch (typename) {
    case TAG_NAMES.CMS_ComponentFormsCheckbox:
      if (state) {
        newProps.checked = true
      }
      break
    case TAG_NAMES.CMS_ComponentFormsTextinput:
    case TAG_NAMES.CMS_ComponentFormsTextarea:
      newProps.value = state ? String(state) : ""
  }
  return newProps
}

const getInputType = (props, typename) => {
  let type = (props.type && props.type.toLowerCase()) || null
  switch (typename) {
    case FIELD_TYPES.Checkbox:
      type = "checkbox"
      break
    case FIELD_TYPES.Hiddenfield:
      type = "hidden"
  }
  return type
}

const FIELD_TYPES = {
  Hiddenfield: "CMS_ComponentFormsHiddenfield",
  Textarea: "CMS_ComponentFormsTextarea",
  Checkbox: "CMS_ComponentFormsCheckbox",
  Textinput: "CMS_ComponentFormsTextinput",
}

const TAG_NAMES = {
  CMS_ComponentFormsHiddenfield: "input",
  CMS_ComponentFormsTextarea: "textarea",
  CMS_ComponentFormsCheckbox: "input",
  CMS_ComponentFormsTextinput: "input",
}
