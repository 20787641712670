import React from "react"
import toCamelCase from "../util/toCamelCase"

const PageSection = props => {
  const obj = {
    ...props,
    className: props.className ? toCamelCase(props.className) : null,
  }
  return (
    <section {...obj}>
      <div className="content">{props.children}</div>
    </section>
  )
}

export default PageSection
