import React from "react"
import { LocationWithMap } from "./location"
import { CONTENT_TYPES, CONTAINERS } from "../classAndIds"
const Locations = ({ locations, classNames = [] }) => {
  return (
    <section
      data-type={CONTAINERS.COLLECTION}
      data-contains={CONTENT_TYPES.LOCATION}
      className={classNames.join(" ")}
    >
      <ul>
        {locations.map(loc => (
          <li key={`li-${loc.id}`}>
            <LocationWithMap {...loc} key={`location-${loc.id}`} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Locations
