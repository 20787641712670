import React, { useContext, useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import favicon from "../images/favicon.ico"
import useMergeTagsToMetadata from "../hooks/useMergeTagsToMetadata"

import {
  SettingsContext,
  SettingsUpdateContext,
} from "../context/SettingsContext"

const SEO = ({ pageTags = [] }) => {
  const settings = useContext(SettingsContext)
  const settingsUpdate = useContext(SettingsUpdateContext)

  const [mergedMetadata] = useState(
    useMergeTagsToMetadata(pageTags, settings.metadata)
  )

  useEffect(() => {
    settingsUpdate.setMetadata(mergedMetadata)
  }, [pageTags, settingsUpdate, mergedMetadata])

  return (
    <Helmet
      htmlAttributes={{ lang: mergedMetadata.lang }}
      title={mergedMetadata.title}
      titleTemplate={mergedMetadata.titleTemplate}
    >
      <link rel="icon" href={favicon} type="image/x-icon"></link>
      <meta name="author" content={mergedMetadata.author} />
      <meta name="description" content={mergedMetadata.description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={mergedMetadata.author} />
      <meta name="twitter:title" content={mergedMetadata.title} />
      <meta name="twitter:description" content={mergedMetadata.description} />
      <meta name="og:title" content={mergedMetadata.title} />
      <meta name="og:description" content={mergedMetadata.description} />
      <meta name="og:type" content="website" />
    </Helmet>
  )
}

export default SEO
