import React from "react"
import CallToAction from "./callToAction"
import Richtext from "./richtext"
import { CONTENT_TYPES, CONTAINERS } from "../classAndIds"
import Heading from "./heading"

const Callout = ({ headline, text, cta, style }) => {
  const altStyle = style && style.toLowerCase() === "alternate"
  return (
    <aside
      data-type={CONTAINERS.COMPONENT}
      data-contains={CONTENT_TYPES.CALLOUT}
      data-variant={(altStyle && "alt") || null}
    >
      {headline && <Heading text={headline} level="1" />}
      {text && <Richtext text={text} />}
      {cta && <CallToAction {...cta} />}
    </aside>
  )
}

export default Callout
