import React, { createContext, useState } from "react"
import useSiteMetadataQuery from "../hooks/useSiteMetadataQuery"
import useSiteCongfigurationQuery from "../hooks/useSiteConfigurationQuery"
import useMergeTagsToMetadata from "../hooks/useMergeTagsToMetadata"

const SettingsContext = createContext()
const SettingsUpdateContext = createContext()

function SettingsProvider({ children }) {
  const { site } = useSiteMetadataQuery()
  const { CMS } = useSiteCongfigurationQuery()

  const globalMetadata = useMergeTagsToMetadata(
    CMS.configuration.metadata.tags,
    site.metadata
  )

  const [metadata, setMetadata] = useState(globalMetadata)
  const [footerLinks] = useState(CMS.configuration.footerLinks)
  const [footerLocation] = useState(CMS.configuration.footerLocation)
  const [sitenav] = useState(CMS.configuration.sitenav)
  const [recaptcha] = useState(CMS.configuration.recaptcha)

  return (
    <SettingsUpdateContext.Provider value={{ setMetadata }}>
      <SettingsContext.Provider
        value={{ metadata, footerLinks, footerLocation, sitenav, recaptcha }}
      >
        {children}
      </SettingsContext.Provider>
    </SettingsUpdateContext.Provider>
  )
}

export { SettingsProvider, SettingsUpdateContext, SettingsContext }
